import { resolvePath } from 'react-router-dom';

import useEnv from '@zenbusiness/application-commons-env';

/**
 * Hook to get the mounted path of this MFE. Takes an argument to resolve full URLs for
 * pages within this application.
 * @param {string} segment
 * @returns {string}
 * @example
 * // If ROOT_SEGMENT is `/dashboard/:uuid/app-template
 * const href = useApplicationPath('/a/b/c');
 * // result: /dashboard/:uuid/app-template/a/b/c
 */
export default function useApplicationPath(segment = '.') {
  const { ROOT_SEGMENT } = useEnv();
  // eslint-disable-next-line no-param-reassign
  if (segment[0] === '/') segment = segment.slice(1);
  return resolvePath(segment, ROOT_SEGMENT).pathname;
}
