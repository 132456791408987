import { useResolvedPath } from 'react-router-dom';

import useEnv from '@zenbusiness/application-commons-env';

/**
 * Hook to get the current path, relative to where the MFE has been mounted in federation.
 * @returns {string}
 * @example
 * // If full url is /dashboard/:uuid/app-template/a/b/c
 * const href = useRelativePath();
 * // result: /a/b/c
 */
export default function useRelativePath() {
  const { ROOT_SEGMENT } = useEnv();
  const CHILD_PATH = useResolvedPath('.', ROOT_SEGMENT).pathname;
  if (CHILD_PATH.startsWith(ROOT_SEGMENT)) {
    return CHILD_PATH.slice(ROOT_SEGMENT.length);
  }

  return CHILD_PATH;
}
