import Heading from '@zenbusiness/zui/Heading';
import Text from '@zenbusiness/zui/Text';

import useApplicationPath from '#client/hooks/useApplicationPath';
import useRelativePath from '#client/hooks/useRelativePath';

export default function Dashboard() {
  const APPLICATION_PATH = useApplicationPath();
  const CHILD_PATH = useRelativePath();
  const RESOLVED_PATH = useApplicationPath('/a/b/c');

  return (
    <>
      <Heading>This is your MFE dashboard page. </Heading>
      <Text>You should put something else here.</Text>
      <Text>
        Your MFE mounted path is&nbsp;
        <code>{APPLICATION_PATH}</code>
      </Text>
      <Text>
        Your path relative to that is&nbsp;
        <code>{CHILD_PATH}</code>
      </Text>
      <Text>
        Here is how to generate a relative url:&nbsp;
        <code>{RESOLVED_PATH}</code>
      </Text>
    </>
  );
}
